<template>
  <Toast :breakpoints="{ '500px': { width: '100%', right: '0', left: '0' } }" />
  <router-view />
</template>

<script>
export default {};
</script>

<style>
@font-face {
  font-family: "Century Gothic";
  src: url("@/assets/CenturyGothic/CenturyGothic-Italic.eot");
  src: local("Century Gothic Italic"), local("CenturyGothic-Italic"),
    url("@/assets/CenturyGothic/CenturyGothic-Italic.eot")
      format("embedded-opentype"),
    url("@/assets/CenturyGothic/CenturyGothic-Italic.woff") format("woff"),
    url("@/assets/CenturyGothic/CenturyGothic-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Century Gothic";
  src: url("@/assets/CenturyGothic/CenturyGothic.eot");
  src: local("Century Gothic"), local("CenturyGothic"),
    url("@/assets/CenturyGothic/CenturyGothic.eot") format("embedded-opentype"),
    url("@/assets/CenturyGothic/CenturyGothic.woff") format("woff"),
    url("@/assets/CenturyGothic/CenturyGothic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Century Gothic";
  src: url("@/assets/CenturyGothic/CenturyGothic-Bold.eot");
  src: local("Century Gothic Bold"), local("CenturyGothic-Bold"),
    url("@/assets/CenturyGothic/CenturyGothic-Bold.eot")
      format("embedded-opentype"),
    url("@/assets/CenturyGothic/CenturyGothic-Bold.woff") format("woff"),
    url("@/assets/CenturyGothic/CenturyGothic-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Century Gothic";
  src: url("@/assets/CenturyGothic/CenturyGothic-BoldItalic.eot");
  src: local("Century Gothic Bold Italic"), local("CenturyGothic-BoldItalic"),
    url("@/assets/CenturyGothic/CenturyGothic-BoldItalic.eot")
      format("embedded-opentype"),
    url("@/assets/CenturyGothic/CenturyGothic-BoldItalic.woff") format("woff"),
    url("@/assets/CenturyGothic/CenturyGothic-BoldItalic.ttf")
      format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans";
  src: url("@/assets/Open Sans/OpenSans-Bold.woff") format("woff");
  font-weight: bold;
}

@font-face {
  font-family: "Open Sans";
  src: url("@/assets/Open Sans/OpenSans-Regular.woff") format("woff");
  font-weight: 400;
}

* {
  font-family: "Century Gothic";
  box-sizing: border-box;
}

body {
  margin: 0;
  background: #000000;
}
.grecaptcha-badge {
  visibility: hidden;
}
</style>
