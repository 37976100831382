import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import PrimeVue from "primevue/config";
import ToastService from "primevue/toastservice";
import "primeicons/primeicons.css";
import "primevue/resources/primevue.min.css";
import "primevue/resources/themes/bootstrap4-dark-blue/theme.css";
import Menubar from "primevue/menubar";
import Button from "primevue/button";
import Password from "primevue/password";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import ProgressSpinner from "primevue/progressspinner";
import Toast from "primevue/toast";
import Calendar from "primevue/calendar";
import Textarea from "primevue/textarea";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import MultiSelect from "primevue/multiselect";
import Checkbox from "primevue/checkbox";
import InputMask from "primevue/inputmask";
import Paginator from "primevue/paginator";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Galleria from "primevue/galleria";
import Slider from "primevue/slider";
import FileUpload from "primevue/fileupload";
import Avatar from "primevue/avatar";

const app = createApp(App);
app
  .use(ToastService)
  .use(store)
  .use(router)
  .use(PrimeVue, {
    locale: {
      startsWith: "Начинается с",
      contains: "Содержит",
      notContains: "Не содержит",
      endsWith: "Заканчивается с",
      equals: "Равняется",
      notEquals: "Не равняется",
      noFilter: "Нет фильтра",
      lt: "Меньше, чем",
      lte: "Меньше или равно",
      gt: "Больше, чем",
      gte: "Больше или равно",
      dateIs: "Дата",
      dateIsNot: "Не ялвяется датой",
      dateBefore: "До даты",
      dateAfter: "После даты",
      clear: "Отчистить",
      apply: "Применить",
      matchAll: "Совпадаются все",
      matchAny: "Любое",
      addRule: "Добавить правило",
      removeRule: "Удалить правило",
      accept: "Да",
      reject: "Нет",
      choose: "Выбрать",
      upload: "Загрузить",
      cancel: "Отменить",
      completed: "Завершенно",
      pending: "В ожидани",
      dayNames: [
        "Воскресенье",
        "Понедельник",
        "Вторник",
        "Среда",
        "Четверг",
        "Пятница",
        "Суббота",
      ],
      dayNamesShort: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
      dayNamesMin: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
      monthNames: [
        "Январь",
        "Февраль",
        "Март",
        "Апрель",
        "Май",
        "Июнь",
        "Июль",
        "Август",
        "Сентябрь",
        "Октябрь",
        "Ноябрь",
        "Декабрь",
      ],
      monthNamesShort: [
        "Янв",
        "Февр",
        "Март",
        "Апр",
        "Май",
        "Июнь",
        "Июль",
        "Авг",
        "Сент",
        "Окт",
        "Нояб",
        "Дек",
      ],
      chooseYear: "Выберите год",
      chooseMonth: "Выберите месяц",
      chooseDate: "Выберите дату",
      prevDecade: "Предыдущая декада",
      nextDecade: "Следующая декана",
      prevYear: "Предыдущий год",
      nextYear: "Следующий год",
      prevMonth: "Предыдущий месяц",
      nextMonth: "Следующий месяц",
      prevHour: "Предыдущий час",
      nextHour: "Следующий час",
      prevMinute: "Предыдущая минута",
      nextMinute: "Следующая минута",
      prevSecond: "Предыдущая секунда",
      nextSecond: "Следующая секунда",
      am: "Утра",
      pm: "Вечера",
      today: "Сегодня",
      weekHeader: "Нед",
      firstDayOfWeek: 1,
      dateFormat: "yy-mm-dd",
      weak: "Неделя",
      medium: "Medium",
      strong: "Strong",
      passwordPrompt: "Введити пароль",
      emptyFilterMessage: "Результаты не найдены",
      searchMessage: "{0} результаты не доступны",
      selectionMessage: "{0} выбранные элементы",
      emptySelectionMessage: "Нет выбранного элемента",
      emptySearchMessage: "Результатов не найдено",
      emptyMessage: "Нет доступных вариантов",
      aria: {
        trueLabel: "Правильно",
        falseLabel: "Неверно",
        nullLabel: "Не выбранно",
        star: "1 звезда",
        stars: "{star} звезд",
        selectAll: "Все элементы выбраны",
        unselectAll: "Ни один элемент не выбран",
        close: "Закрыть",
        previous: "Предыдущий",
        next: "Следующий",
        navigation: "Навигация",
        scrollTop: "Наверх",
        moveTop: "Наверх",
        moveUp: "Вверх",
        moveDown: "Вниз",
        moveBottom: "Вниз",
        moveToTarget: "Перейти к цели",
        moveToSource: "Перейти к источнику",
        moveAllToTarget: "Переместить все к цели",
        moveAllToSource: "Переместить все к источнику",
        pageLabel: "{страница}",
        firstPageLabel: "Первая страница",
        lastPageLabel: "Последняя страница",
        nextPageLabel: "Следующая страница",
        prevPageLabel: "Предыдущая страница",
        rowsPerPageLabel: "Строк на странице",
        previousPageLabel: "Предыдущая страница",
        jumpToPageDropdownLabel: "Перейти к выпадающему списку страниц",
        jumpToPageInputLabel: "Перейти к вводу страницы",
        selectRow: "Выбранная строка",
        unselectRow: "Строка не выбрана",
        expandRow: "Развернуть строку",
        collapseRow: "Свернуть строку",
        showFilterMenu: "Показать меню фильтра",
        hideFilterMenu: "Скрыть меню фильтра",
        filterOperator: "Оператор фильтра",
        filterConstraint: "Ограничение фильтра",
        editRow: "Редактировать строку",
        saveEdit: "Сохранить изменения",
        cancelEdit: "Отменить изменения",
        listView: "Просмотреть списком",
        gridView: "Просмотреть сеткой",
        slide: "Слайд",
        slideNumber: "{Номер}",
        zoomImage: "Увеличить картинку",
        zoomIn: "Увеличьте масштаб",
        zoomOut: "Уменьшите масштаб",
        rotateRight: "Повернуть направо",
        rotateLeft: "Повернуть влево",
      },
    },
    ripple: true,
  })
  // eslint-disable-next-line vue/multi-word-component-names
  .component("Menubar", Menubar)
  .component("Button", Button)
  .component("Password", Password)
  .component("InputText", InputText)
  .component("Dialog", Dialog)
  .component("ProgressSpinner", ProgressSpinner)
  .component("Toast", Toast)
  .component("Calendar", Calendar)
  .component("Textarea", Textarea)
  .component("DataTable", DataTable)
  .component("Column", Column)
  .component("MultiSelect", MultiSelect)
  .component("Checkbox", Checkbox)
  .component("InputMask", InputMask)
  .component("Paginator", Paginator)
  .component("Accordion", Accordion)
  .component("AccordionTab", AccordionTab)
  .component("Galleria", Galleria)
  .component("Slider", Slider)
  .component("FileUpload", FileUpload)
  .component("Avatar", Avatar)
  .mount("#app");
