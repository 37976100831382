import { createStore } from "vuex";

export default createStore({
  state: {
    member: null,
    loginTimes: null,
    registerActive: false,
    loginActive: false,
    soundRecordingModal: false,
    arrangementModal: false,
    mixingModal: false,
    distributionModal: false,
    turnkeyMusicModal: false,
    songwritingModal: false,
    idPreAudio: null,
  },
  getters: {
    preId(state) {
      return state.idPreAudio;
    },
    member(state) {
      return state.member;
    },
    isLogin(state) {
      return state.member != null;
    },
    isAuth(state) {
      if (state.member == null) {
        return !!localStorage.getItem("MEMBER");
      }
      return true;
    },
    loginTimes(state) {
      return state.loginTimes;
    },
    returnName(state) {
      return state.member.login;
    },
  },
  mutations: {
    pausePreAudio(state, id) {
      state.idPreAudio = id;
    },
    setMember(state, member) {
      state.member = member;
      localStorage.setItem("MEMBER", JSON.stringify(member));
    },
    recoveryMember(state) {
      state.member = JSON.parse(localStorage.getItem("MEMBER"));
    },
    initLoginTimes(state) {
      if (localStorage.getItem("LOGINTIMES") == null) {
        state.loginTimes = 0;
      } else {
        state.loginTimes = JSON.parse(localStorage.getItem("LOGINTIMES"));
      }
    },
    setLoginTimes(state, times) {
      state.loginTimes = times;
      localStorage.setItem("LOGINTIMES", JSON.stringify(times));
    },
    logOut(state) {
      state.member = null;
      state.loginTimes = null;
      state.registerActive = false;
      state.loginActive = false;
      localStorage.clear();
    },
  },
  actions: {
    updateLocalStorage(state, payload) {
      state.member = payload;
    },
  },
  modules: {},
});
