import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Main",
    component: () => import("../views/UserViews/MainPage.vue"),
  },
  {
    path: "/admin/add/record",
    name: "AddRecord",
    component: () => import("../views/AdminViews/AddRecord.vue"),
  },
  {
    path: "/admin/record/my",
    name: "myRecords",
    component: () => import("../views/AdminViews/MyRecords.vue"),
  },
  {
    path: "/admin/record/all",
    name: "allRecords",
    component: () => import("../views/AdminViews/AllRecords.vue"),
  },
  {
    path: "/admin/chart",
    name: "chartRecord",
    component: () => import("../views/AdminViews/ChartRecords.vue"),
  },
  {
    path: "/admin/projects",
    name: "OurProjectsAdmin",
    component: () => import("../views/AdminViews/OurProjectsAdmin.vue"),
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("../views/UserViews/PrivacyPage.vue"),
  },
  {
    path: "/price",
    name: "price",
    component: () => import("../views/UserViews/ServicePrice.vue"),
  },
  {
    path: "/gallery",
    name: "Gallery",
    component: () => import("../views/UserViews/GalleryPage.vue"),
  },
  {
    path: "/projects",
    name: "OurProjects",
    component: () => import("../views/UserViews/OurProjects.vue"),
  },
  {
    path: "/error",
    name: "Error",
    component: () => import("../views/UserViews/PageError.vue"),
  },
  {
    path: "/:catchAll(.*)",
    component: () => import("../views/UserViews/PageError.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
